import React, { useState, useEffect } from "react";
import {adminHost, host} from "../../http";
import icons from "../../assets/icons/coin_map.json";
import SeeMore from "../../assets/images/seemore.svg";
import Electro from "../../assets/images/electro.svg";
import arrow_left from "../../assets/images/arrowadminleft.svg";
import arrow_right from "../../assets/images/arrowadminright.svg";
import Close from "../../assets/images/close.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alert from "../modals/Alert";

const DepositS = ({ options }) => {
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [statusF, setStatusF] = useState(null);
  const [tokenF, setTokenF] = useState("");
  const [dateF, setDateF] = useState(null);
  const [networkF, setNetworkF] = useState(null);

  const [depositList, setDepositList] = useState(null);
  const [depCurrPage, setDepCurrPage] = useState(1);
  const [depTotalPages, setDepTotalPages] = useState(null);
  const [filterHandle, setFilterHandle] = useState(false);

  const [filterKey, setFilterKey] = useState("");

  const paginatorRender = () => {
    const buttons = [];
    if (depTotalPages <= 10) {
      for (let i = 1; i <= depTotalPages; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${depCurrPage === i ? "active_page" : ""}`} onClick={() => setDepCurrPage(i)}>
            {i}
          </div>
        );
      }
    } else {
      for (let i = 1; i <= 5; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${depCurrPage === i ? "active_page" : ""}`} onClick={() => setDepCurrPage(i)}>
            {i}
          </div>
        );
      }
      buttons.push(
        <div key={0} className={`page-button margin`}>
          ...
        </div>
      );
      for (let i = depTotalPages - 4; i <= depTotalPages; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${depCurrPage === i ? "active_page" : ""}`} onClick={() => setDepCurrPage(i)}>
            {i}
          </div>
        );
      }
    }

    return buttons;
  };

  useEffect(() => {
    const params = {};
    if (filterKey) {
      params.search = filterKey;
    } else {
      if (statusF) params.status = statusF.toLowerCase();
      if (tokenF) params.coin = tokenF;
      if (dateF) params.date = dateF;
    }
    adminHost
      .get(`/api/deposits/?${new URLSearchParams(params)}`)
      .then((response) => {
        const newTotalPages = Math.ceil(response.data.count / 10);
        setDepTotalPages(newTotalPages);
        if (depCurrPage >= newTotalPages) {
          setDepCurrPage(1);
        }
        if (newTotalPages <= 1) {
          setDepositList(response.data);
          return;
        }
        params.page = newTotalPages;
        adminHost
          .get(`/api/deposits/?${new URLSearchParams(params)}`)
          .then((res) => {
            setDepCurrPage(1);
            setDepositList(res.data);
          })
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }, [filterHandle]);

  useEffect(() => {
    const params = {};
    if (depTotalPages && depCurrPage) {
      if (filterKey) {
        params.search = filterKey;
      } else {
        if (statusF) params.status = statusF.toLowerCase();
        if (tokenF) params.coin = tokenF;
        if (dateF) params.date = dateF;
      }
      params.page = Math.min(depTotalPages - depCurrPage + 1, depTotalPages);

      adminHost
        .get(`/api/deposits/?${new URLSearchParams(params)}`)
        .then((response) => {
          setDepositList(response.data); // Destructure response.data
          setDepTotalPages(Math.ceil(response.data.count / 10));
        })
        .catch((e) => console.error(e));
    }
  }, [depCurrPage]);

  const renderEmptyRows = (rows) => {
    const res_rows = [];
    for (let i = rows; i < 10; i++) {
      res_rows.push(
        <tr className="content_table">
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
        </tr>
      );
    }
    return res_rows;
  };

  return (
    <div className="right_block">
      <ul className="navbar">
        <li className="hover">
          <input
            type="text"
            className="select"
            placeholder="Status"
            value={statusF}
            onChange={(e) => {
              setStatusF(e.target.value);
              setFilterKey("");
            }}
            style={{ background: "none", border: "none", padding: "0", margin: "0", width: "100%", height: "20px" }}
          />{" "}
          {statusF ? (
            <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setStatusF("")} alt="" />
          ) : (
            <img src={SeeMore} className="drop-arrow" alt="" />
          )}
          <ul className="displaynone">
            <button
              className="dropbtn"
              onClick={() => {
                setStatusF("");
                setFilterKey("");
              }}
            >
              All
            </button>
            <button
              className="Confirmation dropbtn"
              onClick={() => {
                setStatusF("CONFIRMATION");
                setFilterKey("");
              }}
            >
              Confirmation
            </button>
            <button
              className="Success dropbtn"
              onClick={() => {
                setStatusF("SUCCESS");
                setFilterKey("");
              }}
            >
              Success
            </button>
            <button
              className="Overdue dropbtn"
              onClick={() => {
                setStatusF("ERROR");
                setFilterKey("");
              }}
            >
              Error
            </button>
            <button
              className="Overdue dropbtn"
              onClick={() => {
                setStatusF("STOPPED");
                setFilterKey("");
              }}
            >
              Stopped
            </button>
          </ul>
        </li>
        <li className="hover ">
          <input
            type="text"
            className="select"
            placeholder="Currency"
            value={tokenF}
            onChange={(e) => {
              setTokenF(e.target.value);
              setFilterKey("");
            }}
            style={{ background: "none", border: "none", padding: "0", margin: "0", width: "100%", height: "20px" }}
          />{" "}
          {tokenF ? (
            <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setTokenF("")} alt="" />
          ) : (
            <img src={SeeMore} className="drop-arrow" alt="" />
          )}
          <ul className="displaynone currency_style">
            {options
              ?.filter((el) => el?.value?.includes(tokenF))
              ?.map((opt) => {
                const crypto_icon = icons[opt?.value.toUpperCase()] ? icons[opt?.value.toUpperCase()] : icons["BTC"];
                return (
                  <li
                    onClick={() => {
                      setTokenF(opt?.value);
                      setNetworkF("");
                      setFilterKey("");
                    }}
                  >
                    <p style={{ display: "flex", cursor: "pointer", alignItems: "center" }}>
                      <img src={crypto_icon} width={20} style={{ borderRadius: "5px", marginRight: "10px" }} alt={opt?.value} />
                      {opt?.value}
                    </p>
                  </li>
                );
              })}
          </ul>
        </li>
        <li className="hover">
          <input
            type="text"
            className="select"
            placeholder="Network"
            value={networkF}
            onChange={(e) => {
              setNetworkF(e.target.value);
              setFilterKey("");
            }}
            style={{ background: "none", border: "none", padding: "0", margin: "0", width: "100%", height: "20px" }}
          />{" "}
          {networkF ? (
            <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setNetworkF("")} alt="" />
          ) : (
            <img src={SeeMore} className="drop-arrow" alt="" />
          )}
          <ul className="displaynone">
            {tokenF ? (
              options
                ?.find((opt) => opt?.value === tokenF)
                ?.networks?.withdraw?.map((net) => {
                  return (
                    <li
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setNetworkF(net);
                        setFilterKey("");
                      }}
                    >
                      {net}
                    </li>
                  );
                })
            ) : (
              <li>Empty list</li>
            )}
          </ul>
        </li>
        <li>
          <DatePicker
            isClearable
            className="datepicker"
            placeholderText="Select date"
            dateFormat="dd-MM-yyyy"
            selected={dateF}
            onChange={(date) => {
              if (date === null) {
                setDateF("");
              } else {
                setDateF("" + new Date(date).toISOString().split("T")[0]);
                setFilterKey("");
              }
            }}
          />
        </li>
        <li style={{ position: "relative" }}>
          <input
            className="Filterbykey"
            placeholder="Search by ID"
            value={filterKey}
            type="text"
            onChange={(e) => {
              setFilterKey(e.target.value);
              setTokenF("");
              setNetworkF("");
              setStatusF("");
              setDateF(null);
            }}
          />
          {filterKey ? <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setFilterKey("")} alt="" /> : null}
        </li>
        <button id="admin-pnl-btn" onClick={() => setFilterHandle(!filterHandle)}>
          <img src={Electro} alt="" />
        </button>
      </ul>
      <div className="content_block">
        <table className="table">
          <tr className="th_names">
            <th className="radius">Time</th>
            <th>ID</th>
            <th>Status</th>
            <th>Platform</th>
            <th>Coin</th>
            <th>TxID</th>
            <th>Address</th>
            <th className="radius2">Risk Score</th>
          </tr>

          {depositList?.results
            ?.slice()
            ?.reverse()
            ?.map((item) => {
              return (
                <tr className="content_table">
                  <td>
                    <div className="time">{new Date(item?.deposit_time).toLocaleString("uk-UA").split(".").join("/")}</div>
                  </td>
                  <td>
                    <div className="url_block" style={{ alignItems: "center" }}>
                      <div
                        className="Url copy-element"
                        onClick={() => {
                          navigator.clipboard.writeText(item?.deposit_id);
                          setAlertIsOpen(true);
                        }}
                      >
                        {item?.deposit_id?.substr(0, 6) + "..." + item?.deposit_id?.substr(item?.deposit_id?.length - 5, item?.deposit_id?.length)}
                      </div>
                      <div className="market">{}</div>
                    </div>
                  </td>
                  <td className="padding">
                    <div className={item?.status ? `status status${item?.status.toUpperCase()}` : "status"}>{item?.status.toUpperCase()}</div>
                  </td>
                  <td className="padding">
                    <div className="type">{item?.platform}</div>
                  </td>
                  <td className="padding">
                    <div className="part_profit">
                      {+item?.amount} {item?.coin}
                    </div>
                  </td>
                  <td className="padding">
                    <div
                      className="part_profit copy-element"
                      onClick={() => {
                        navigator.clipboard.writeText(item?.txid);
                        setAlertIsOpen(true);
                      }}
                    >
                      {item?.txid?.substr(0, 6) + "..." + item?.txid?.substr(item?.txid?.length - 5, item?.txid?.length)}
                    </div>
                  </td>
                  <td className="padding">
                    <div
                      className="part_profit copy-element"
                      onClick={() => {
                        navigator.clipboard.writeText(item?.address);
                        setAlertIsOpen(true);
                      }}
                    >
                      {item?.address?.substr(0, 6) + "..." + item?.address?.substr(item?.address?.length - 5, item?.address?.length)}
                    </div>
                  </td>
                  <td className="padding">
                    <div className="part_profit">-</div>
                  </td>
                </tr>
              );
            })}
          {renderEmptyRows(depositList?.results?.length)}
        </table>
        <div className="slidepages" style={{ userSelect: "none" }}>
          <div
            className="arrowleft margin"
            onClick={() => {
              depositList?.next && setDepCurrPage((prev) => prev - 1);
            }}
          >
            <img src={arrow_left} alt="" />
          </div>
          {paginatorRender()}
          <div
            className="arrowright margin"
            onClick={() => {
              depositList?.previous && setDepCurrPage((prev) => prev + 1);
            }}
          >
            <img src={arrow_right} alt="" />
          </div>
        </div>
      </div>
      {alertIsOpen ? <Alert onClose={() => setAlertIsOpen(false)} /> : null}
    </div>
  );
};

export default DepositS;
