import React, { useState, useEffect } from "react";
import {adminHost, host} from "../../http";
import icons from "../../assets/icons/coin_map.json";
import SeeMore from "../../assets/images/seemore.svg";
import Electro from "../../assets/images/electro.svg";
import arrow_left from "../../assets/images/arrowadminleft.svg";
import Close from "../../assets/images/close.svg";
import arrow_right from "../../assets/images/arrowadminright.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CreateOrder from "../modals/CreateOrder";
import Alert from "../modals/Alert";

const OrdersS = ({ options }) => {
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [statusF, setStatusF] = useState(null);
  const [tokenF, setTokenF] = useState("");
  const [dateF, setDateF] = useState(null);
  const [networkF, setNetworkF] = useState(null);

  const [orderList, setOrderList] = useState(null);
  const [orderCurrPage, setOrderCurrPage] = useState(1);
  const [orderTotalPages, setOrderTotalPages] = useState(null);

  const [filterHandle, setFilterHandle] = useState(false);

  const [filterKey, setFilterKey] = useState("");

  const paginatorRender = () => {
    const buttons = [];
    if (orderTotalPages <= 10) {
      for (let i = 1; i <= orderTotalPages; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${orderCurrPage === i ? "active_page" : ""}`} onClick={() => setOrderCurrPage(i)}>
            {i}
          </div>
        );
      }
    } else {
      for (let i = 1; i <= 5; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${orderCurrPage === i ? "active_page" : ""}`} onClick={() => setOrderCurrPage(i)}>
            {i}
          </div>
        );
      }
      buttons.push(
        <div key={0} className={`page-button margin`}>
          ...
        </div>
      );
      for (let i = orderTotalPages - 4; i <= orderTotalPages; i++) {
        buttons.push(
          <div key={i} className={`page-button margin ${orderCurrPage === i ? "active_page" : ""}`} onClick={() => setOrderCurrPage(i)}>
            {i}
          </div>
        );
      }
    }

    return buttons;
  };

  useEffect(() => {
    const params = {};
    if (filterKey) {
      params.search = filterKey;
    } else {
      if (statusF) params.status = statusF.toLowerCase();
      if (tokenF) params.pair = tokenF;
      if (dateF) params.date = dateF;
    }
    adminHost
      .get(`/api/orders/?${new URLSearchParams(params)}`)
      .then((response) => {
        const newTotalPages = Math.ceil(response.data.count / 10);
        setOrderTotalPages(newTotalPages);
        if (orderCurrPage >= newTotalPages) {
          setOrderCurrPage(1);
        }
        if (newTotalPages <= 1) {
          setOrderList(response.data);
          return;
        }
        params.page = newTotalPages;
        adminHost
          .get(`/api/orders/?${new URLSearchParams(params)}`)
          .then((res) => {
            setOrderCurrPage(1);
            setOrderList(res.data);
          })
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }, [filterHandle]);

  useEffect(() => {
    const params = {};
    if (orderTotalPages && orderCurrPage) {
      if (filterKey) {
        params.search = filterKey;
      } else {
        if (statusF) params.status = statusF.toLowerCase();
        if (tokenF) params.pair = tokenF;
        if (dateF) params.date = dateF;
      }
      params.page = Math.min(orderTotalPages - orderCurrPage + 1, orderTotalPages);

      adminHost
        .get(`/api/orders/?${new URLSearchParams(params)}`)
        .then((response) => {
          setOrderList(response.data); // Destructure response.data
          setOrderTotalPages(Math.ceil(response.data.count / 10));
        })
        .catch((e) => console.error(e));
    }
  }, [orderCurrPage]);

  const renderEmptyRows = (rows) => {
    const res_rows = [];
    for (let i = rows; i < 10; i++) {
      res_rows.push(
        <tr className="content_table">
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
          <td className="padding"></td>
        </tr>
      );
    }
    return res_rows;
  };

  return (
    <div className="right_block">
      <ul className="navbar">
        <li className="hover">
          <input
            type="text"
            className="select"
            placeholder="Status"
            value={statusF}
            onChange={(e) => {
              setStatusF(e.target.value);
              setFilterKey("");
            }}
            style={{ background: "none", border: "none", padding: "0", margin: "0", width: "100%", height: "20px" }}
          />
          {statusF ? (
            <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setStatusF("")} alt="" />
          ) : (
            <img src={SeeMore} className="drop-arrow" alt="" />
          )}
          <ul className="displaynone">
            <button className="dropbtn"
              onClick={() => {
                setStatusF("");
                setFilterKey("");
              }}
            >
              All
            </button>
            <button className="Success dropbtn"
              onClick={() => {
                setStatusF("FILLED");
                setFilterKey("");
              }}>
              Filled
            </button>
            <button className="Overdue dropbtn"
              onClick={() => {
                setStatusF("CANCELED");
                setFilterKey("");
              }}>
              Canceled
            </button>
          </ul>
        </li>
        <li className="hover ">
          <input
            type="text"
            className="select"
            placeholder="Currency"
            value={tokenF}
            onChange={(e) => {
              setTokenF(e.target.value);
              setFilterKey("");
            }}
            style={{ background: "none", border: "none", padding: "0", margin: "0", width: "100%", height: "20px" }}
          />
          {tokenF ? (
            <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setTokenF("")} alt="" />
          ) : (
            <img src={SeeMore} className="drop-arrow" alt="" />
          )}
          <ul className="displaynone currency_style">
            {options
              ?.filter((el) => el?.value?.includes(tokenF))
              ?.map((opt) => {
              const crypto_icon = icons[opt?.value.toUpperCase()] ? icons[opt?.value.toUpperCase()] : icons["BTC"];
              return (
                <li
                  onClick={() => {
                    setTokenF(opt?.value);
                    setNetworkF("");
                    setFilterKey("");
                  }}
                >
                  <p style={{ display: "flex", cursor: "pointer", alignItems: "center" }}>
                    <img src={crypto_icon} width={20} style={{ borderRadius: "5px", marginRight: "10px" }} alt={opt?.value} />
                    {opt?.value}
                  </p>
                </li>
              );
            })}
          </ul>
        </li>
        <li className="hover">
          <input
            type="text"
            className="select"
            placeholder="Network"
            value={networkF}
            onChange={(e) => {
              setNetworkF(e.target.value);
              setFilterKey("");
            }}
            style={{ background: "none", border: "none", padding: "0", margin: "0", width: "100%", height: "20px" }}
          />
          {networkF ? (
            <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setNetworkF("")} alt="" />
          ) : (
            <img src={SeeMore} className="drop-arrow" alt="" />
          )}
          <ul className="displaynone">
            {tokenF ? (
              options
                ?.find((opt) => opt?.value === tokenF)
                ?.networks?.withdraw?.map((net) => {
                  return (
                    <li style={{ cursor: "pointer" }}
                    onClick={() => {
                      setNetworkF(net);
                      setFilterKey("");
                    }}
                    >
                      {net}
                    </li>
                  );
                })
            ) : (
              <li>Empty list</li>
            )}
          </ul>
        </li>
        <li>
          <DatePicker
            isClearable
            className="datepicker"
            placeholderText="Select date"
            dateFormat="dd-MM-yyyy"
            selected={dateF}
            onChange={(date) => {
              if (date === null) {
                setDateF("");
              } else {
                setDateF("" + new Date(date).toISOString().split("T")[0]);
                setFilterKey("");
              }
            }}
          />
        </li>
        <li style={{ position: "relative" }}>
          <input
            className="Filterbykey"
            placeholder="Search by ID"
            value={filterKey}
            type="text"
            onChange={(e) => {
              setFilterKey(e.target.value);
              setTokenF("");
              setNetworkF("");
              setStatusF("");
              setDateF(null);
            }}
          />
          {filterKey ? <img src={Close} width={12} className="drop-arrow" style={{ opacity: 0.5, cursor: "pointer" }} onClick={() => setFilterKey("")} alt="" /> : null}
        </li>
        <button id="admin-pnl-btn" onClick={() => setFilterHandle(!filterHandle)}>
          <img src={Electro} alt="" />
        </button>
        <button id="create-order-btn" onClick={() => setIsModalOpen(true)}>
          CREATE ORDER
        </button>
      </ul>
      <div className="content_block">
        <table className="table">
          <tr className="th_names">
            <th className="radius">ID</th>
            <th>Time</th>
            <th>Platform</th>
            <th>Pair</th>
            <th>Price</th>
            <th>Amount</th>
            <th>Filled</th>
            <th>Total</th>
            <th>Side</th>
            <th>Type</th>
            <th>Status</th>
            <th className="radius2">Fee</th>
          </tr>

          {orderList?.results
            ?.slice()
            ?.reverse()
            ?.map((item) => {
              console.log(item, "item")
              return (
                <tr className="content_table">
                  <td>
                    <div className="url_block">
                      <div
                        className="Url copy-element"
                        onClick={() => {
                          navigator.clipboard.writeText(item?.order_id);
                          setAlertIsOpen(true);
                        }}
                      >
                        {item?.order_id?.length >= 24 ? item?.order_id?.substr(0, 6) + "..." + item?.order_id?.substr(item?.order_id?.length - 5, item?.order_id?.length) : item?.order_id}
                      </div>
                      <div className="market">Swaponix.io ({+((+item?.fee / +item?.total) * 100).toFixed(2)}%)</div>
                      <div className="exchange">{item?.exchange_id}</div>
                    </div>
                  </td>
                  <td className="padding">
                    <div className="time">{new Date(item?.order_time).toLocaleString("uk-UA").split(".").join("/")}</div>
                  </td>
                  <td className="padding">
                    <div className="profit">{item?.platform}</div>
                  </td>
                  <td className="padding">
                    <div className="volume">{(item?.pair).split("_").join("/")}</div>
                  </td>
                  <td className="padding">
                    <div className="type upcolor">{(+item?.price)} USDT</div>
                  </td>
                  <td className="padding">
                    <div className="part_profit">{(+item?.amount)} {(item?.pair).split("_")[0]}</div>
                  </td>
                  <td className="padding">
                    <div className="part_profit">
                      {(+item?.filled)} {(item?.pair).split("_")[0]}
                    </div>
                  </td>
                  <td className="padding">
                    <div className="part_profit upcolor">{+item?.total} {(item?.pair).split("_")[1]}</div>
                  </td>
                  <td className="padding">
                    <div className={item?.side ? `status status${item?.side?.toUpperCase()}` : "status"}>{item?.side.toUpperCase()}</div>
                  </td>
                  <td className="padding">
                    <div className="part_profit">{item?.order_type.replace(/^\w/, (c) => c.toUpperCase())}</div>
                  </td>
                  <td className="padding">
                    <div className={item?.status ? `status status${item?.status.toUpperCase()}` : "status"}>{item?.status.toUpperCase()}</div>
                  </td>
                  <td className="padding">
                    <div className="part_profit downcolor">
                      {(+item?.fee)} USDT ({+((+item?.fee / +item?.total) * 100).toFixed(2)}%)
                    </div>
                  </td>
                </tr>
              );
            })}
          {renderEmptyRows(orderList?.results?.length)}
        </table>
        <div className="slidepages" style={{userSelect: "none"}}>
          <div
            className="arrowleft margin"
            onClick={() => {
              orderList?.next && setOrderCurrPage((prev) => prev - 1);
            }}
          >
            <img src={arrow_left} alt="" />
          </div>
          {paginatorRender()}
          <div
            className="arrowright margin"
            onClick={() => {
              orderList?.previous && setOrderCurrPage((prev) => prev + 1);
            }}
          >
            <img src={arrow_right} alt="" />
          </div>
        </div>
      </div>
      <CreateOrder isModalOpen={isModalOpen} onClose={() => {
        setIsModalOpen(false);
        setFilterHandle(prev => !prev)
      }} />
      {alertIsOpen ? <Alert onClose={() => setAlertIsOpen(false)} /> : null}
    </div>
  );
};

export default OrdersS;
