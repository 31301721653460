import React, { useState, useEffect } from "react";
import {adminHost, host} from "../../http";
import Close from "../../assets/images/close.svg";

const CreateOrder = ({ isModalOpen, onClose }) => {
  const [base, setBase] = useState(null);
  const [amount, setAmount] = useState(null);
  const [price, setPrice] = useState(null);
  const [side, setSide] = useState(null);
  const [type, setType] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [fee, setFee] = useState(null);

  const [orderCreated, setOrderCreated] = useState(null);

  const [openedSelect, setOpenedSelect] = useState(null);

  useEffect(() => {
    adminHost
      .get("/api/feesettings/")
      .then((response) => {
        setFee(response?.data?.results);
      })
      .catch((e) => console.log(e));
  }, []);

  if (isModalOpen !== true) {
    return null;
  }

  const dec2hex = (dec) => {
    return dec.toString(16).padStart(2, "0");
  };

  const generateId = (len) => {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  };

  const createNewOrder = () => {
      let data = {
        platform: platform,
        pair: base,
        price: price,
        amount: amount,
        side: side,
        order_type: type
      };
      adminHost
        .post("/api/orders/", data)
        .then((response) => {
          setOrderCreated(response?.data);
          setTimeout(() => onClose(), 1500)
        })
        .catch((e) => console.log(e));
  };

  return (
    <section className="modal">
      <article className="modal-content">
        <div className="exit-icon">
          <div className="div-2">CREATE ORDER</div>
          <img
            src={Close}
            width={30}
            onClick={() => {
              setOrderCreated(null);
              onClose();
            }}
            alt=""
          />
        </div>
        {/* <main className="modal-mainContents"> */}
        <div className="div">
          <div className="div-3">
            <div className="css-gebwvy-control">
              <div className="css-ValueContainer">
                <div className="css-singleValue">
                  <input type="text" placeholder="Base" onChange={(e) => setBase(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="div-3">
            <div className="css-gebwvy-control">
              <div className="css-ValueContainer">
                <div className="css-singleValue">
                  <input type="text" placeholder="Amount" onChange={(e) => setAmount(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="div-3">
            <div className="css-gebwvy-control">
              <div className="css-ValueContainer">
                <div className="css-singleValue">
                  <input type="text" placeholder="Price" onChange={(e) => setPrice(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
          <div className="opened">
            <div className="div-7">
              <div
                className="css-gebwvy-control"
                onClick={() => {
                  openedSelect === "side" ? setOpenedSelect(null) : setOpenedSelect("side");
                }}
              >
                <div className="css-ValueContainer">
                  <div className="css-singleValue">
                    <span style={{ marginLeft: "5px", fontWeight: 600, fontSize: "1rem" }}>{side ? side.toUpperCase() : "Order Side"}</span>
                  </div>
                </div>
                <div className="css-IndicatorsContainer">
                  <div className="css-indicatorContainer">
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-Svg">
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className={openedSelect === "side" ? "order_select_items" : "hidden"}>
              <span
                style={{ backgroundColor: "#028507" }}
                onClick={() => {
                  setSide("buy");
                  setOpenedSelect(null);
                }}
              >
                BUY
              </span>
              <span
                style={{ backgroundColor: "#B72121" }}
                onClick={() => {
                  setSide("sell");
                  setOpenedSelect(null);
                }}
              >
                SELL
              </span>
            </div>
          </div>
          <div className="opened">
            <div className="div-7">
              <div
                className="css-gebwvy-control"
                onClick={() => {
                  openedSelect === "type" ? setOpenedSelect(null) : setOpenedSelect("type");
                }}
              >
                <div className="css-ValueContainer">
                  <div className="css-singleValue">
                    <span style={{ marginLeft: "5px", fontWeight: 600, fontSize: "1rem" }}>{type ? type.toUpperCase() : "Order Type"}</span>
                  </div>
                </div>
                <div className="css-IndicatorsContainer">
                  <div className="css-indicatorContainer">
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-Svg">
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className={openedSelect === "type" ? "order_select_items" : "hidden"}>
              <span
                onClick={() => {
                  setType("market");
                  setOpenedSelect(null);
                }}
              >
                Market
              </span>
              <span
                onClick={() => {
                  setType("limit");
                  setOpenedSelect(null);
                }}
              >
                Limit
              </span>
            </div>
          </div>
          <div className="opened">
            <div className="div-7">
              <div
                className="css-gebwvy-control"
                onClick={() => {
                  openedSelect === "platform" ? setOpenedSelect(null) : setOpenedSelect("platform");
                }}
              >
                <div className="css-ValueContainer">
                  <div className="css-singleValue">
                    <span style={{ marginLeft: "5px", fontWeight: 600, fontSize: "1rem" }}>{platform ? platform : "Order Platform"}</span>
                  </div>
                </div>
                <div className="css-IndicatorsContainer">
                  <div className="css-indicatorContainer">
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-Svg">
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className={openedSelect === "platform" ? "order_select_items" : "hidden"}>
              <span
                onClick={() => {
                  setPlatform("WHITEBIT");
                  setOpenedSelect(null);
                }}
              >
                WHITEBIT
              </span>
              <span
                onClick={() => {
                  setPlatform("MEXC");
                  setOpenedSelect(null);
                }}
              >
                MEXC
              </span>
            </div>
          </div>
          {orderCreated ? (
            <span style={{ textAlign: "center", fontSize: "1rem", fontWeight: 600, fontFamily: "Montserrat", margin: "25px 0 10px" }}>Order created!</span>
          ) : (
            <button className="div-10" onClick={() => createNewOrder()}>
              Submit
            </button>
          )}
        </div>
      </article>
      <style jsx>{`
        .modal {
          position: fixed;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .modal-content {
          border: 1px solid #fff;
          width: 450px;
          background: linear-gradient(91deg, #7927ff 0%, #8b1ea6 100%);
          border: none;
          border-radius: 1.5rem;
        }
        .exit-icon {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          padding: 15px 20px 0 25px;
          align-items: center;
          font-size: 20px;
          margin-bottom: 20px;
        }
        .modal-title {
          color: #6821c3;
          font-weight: 700;
          font-size: 30px;
          line-height: 28px;
        }
        .modal-image img {
          width: 8rem;
        }
        .modalText {
          text-align: justify;
        }
        .modal-button button {
          border: 1px solid #6821c3;
          padding: 6px;
          width: 8rem;
          background-color: #6821c3;
          color: white;
          border-radius: 7px;
        }
        .div {
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          font-size: 11px;
          color: #fff;
          font-weight: 600;
          padding: 0 25px 20px;
        }
        .div-2 {
          font: 700 18px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
          color: #fff;
        }
        .div-3 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.6rem;
          border-color: rgba(255, 255, 255, 1);
          background-color: rgba(255, 255, 255, 0.1);
          border-style: solid;
          border-width: 1px;
          margin-top: 9px;
          font-weight: 500;
          white-space: nowrap;
          justify-content: center;
          padding: 2px 5px;
        }
        .opened {
          width: 100%;
          padding: 0;
          margin: 0;
          margin-top: 7px;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 0.6rem;
        }
        .hidden {
          display: none;
        }
        .order_select_items {
          display: block;
        }
        .div-7 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.6rem;
          white-space: nowrap;
          justify-content: center;
          padding: 2px 5px;
        }
        .order_select_items {
          display: flex;
          justify-content: center;
        }
        .order_select_items span {
          width: 100%;
          font-family: Montserrat, sans-serif;
          font-size: 0.8rem;
          border-radius: 0.6rem;
          background-color: rgba(0, 0, 0, 0.6);
          padding: 15px 0;
          margin: 10px;
          text-align: center;
          cursor: pointer;
        }
        .div-8 {
          border-radius: 7px;
          backdrop-filter: blur(2px);
          background-color: rgba(108, 108, 108, 0.7);
          display: flex;
          margin-top: 7px;
          gap: 20px;
          white-space: nowrap;
          justify-content: space-between;
          padding: 12px 12px 12px 6px;
        }
        .div-9 {
          font-family: Montserrat, sans-serif;
        }
        .img {
          aspect-ratio: 1.43;
          object-fit: auto;
          object-position: center;
          width: 10px;
          fill: #fff;
          align-self: start;
        }
        .div-10 {
          font-family: Montserrat, sans-serif;
          border-radius: 0.5rem;
          border: none;
          background-color: #27ae60;
          align-self: center;
          margin-top: 6px;
          white-space: nowrap;
          justify-content: center;
          padding: 15px 50px;
          font-size: 1rem;
          color: #fff;
          font-weight: 600;
          margin: 20px 0 0 0;
          cursor: pointer;
        }
      `}</style>
    </section>
  );
};

export default CreateOrder;
