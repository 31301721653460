import React, { useCallback, useEffect, useMemo, useState } from "react";
import Close from "../assets/images/close.svg";
import { host } from "../http";
import InfiniteScroll from "react-infinite-scroll-component";
import {getLogo} from "../utils/logo";

const SelectCust = ({
  isOpen,
  setIsOpen,
  options,
  selectedOption,
  setSelectedOption,
  updatePrice,
  changeShow,
  setSearch,
}) => {
  const [coins, setCoins] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemOpened, setItemOpened] = useState('');
  const fetchCoins = useCallback(
    async (pageNumber, query) => {
      setLoading(true);
      let result = [];
      try {
        const response = await host.get(`/coin/api/coin/`, {
          params: { page: pageNumber, page_size: 20, search: query },
        });
        const newCoins = response.data.results;

        result = newCoins;
        setHasMore(!!response.data.next);
      } catch (error) {
        console.error("Error fetching coins:", error);
      } finally {
        setLoading(false);
      }

      return result;
    },
    [page]
  );

  useEffect(() => {
    if (!coins.length) fetchCoins(page).then(setCoins);
  }, []);

  const loadMoreCoins = () => {
    console.log("LOAD MORE COINS");
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
      fetchCoins(page + 1, searchQuery).then((res) =>
        setCoins((prev) => [...prev, ...res])
      );
    }
  };

  const handleOnSearch = (query) => {
    if (!query) {
      fetchCoins(1).then(setCoins);
    }
    setCoins([]);
    setPage(1);
    setSearchQuery(query);
    fetchCoins(1, query)
      .then((res) => {
        return res;
      })
      .then(setCoins);
  };

  if (!isOpen) return null;
  return (
    <>
      <div
        className="div"
        style={{
          zIndex: "100",
          position: "absolute",
          marginTop: "10px",
          width: "100%",
        }}
      >
        <div className="div-2">
          <div className="div-3">Select Token</div>
          <img
            loading="lazy"
            src={Close}
            className="close_icon"
            alt=""
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
        <input
          type="text"
          value={searchQuery}
          placeholder="Enter token name"
          className="div-4"
          onChange={(e) => handleOnSearch(e.target.value)}
        />

        <div
          className="div-6 scrollableDiv"
          id="scrollableDiv"
          style={{ overflowX: "hidden"}}
        >
          <InfiniteScroll
            dataLength={coins.length}
            next={(props) => {
              loadMoreCoins();
            }}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            {coins.map((opt) => {
              return (
                <>
                  <div
                    key={opt.token}
                    className={
                      selectedOption === opt
                        ? "card_coin_select coin_selected"
                        : "card_coin_select"
                    }
                    style={{ position: "relative", boxSizing: "border-box" }}
                    onClick={() => {
                      itemOpened === opt
                        ? setItemOpened(null)
                        : setItemOpened(opt);
                      if (updatePrice) {
                        updatePrice();
                      }
                    }}
                  >
                    <div className="css-gebwvy-control">
                      <div className="css-ValueContainer">
                        <div className="css-singleValue">
                          <div
                            style={{display: "flex", alignItems: "center"}}
                          >
                            <img
                              loading="lazy"
                              src={getLogo(opt.token)}
                              width={30}
                              className="coin_icon"
                              alt=""
                            />
                            <span
                              style={{marginLeft: "5px", fontWeight: 600}}
                            >
                              {opt?.token}{" "}
                              <span style={{fontWeight: 500, opacity: 0.5}}>
                                {opt?.title}
                              </span>
                              <br/>
                              <span
                                style={{
                                  fontSize: "10px",
                                  color: "rgb(136, 136, 136)",
                                  fontWeight: 400,
                                }}
                              >
                                {opt?.networks
                                  .map((network) => network.title)
                                  .join(" ")}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="css-IndicatorsContainer">
                      <div className="css-indicatorContainer" style={{transform: itemOpened?.token === opt.token ? "rotate(-180deg)": null}}>
                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"
                             className="css-Svg">
                          <path
                            d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "6px",
                    }}
                  >
                    {opt?.networks?.map((network) => {
                      return (
                        <div
                          className={
                            itemOpened?.token === opt.token
                              ? "card_coin_select"
                              : "select-item-none"
                          }
                          style={{
                            position: "relative",
                            boxSizing: "border-box",
                            background: "#333",
                          }}
                          onClick={() => {
                            setSelectedOption({
                              id: network.id,
                              token: opt.token,
                              title: opt.title,
                              networks: [network],
                              is_memo: opt.is_memo,
                            });
                            if (updatePrice) updatePrice((prev) => !prev);
                            setIsOpen(false);
                          }}
                        >
                          <div className="css-gebwvy-control">
                            <div className="css-ValueContainer">
                              <div className="css-singleValue">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    loading="lazy"
                                    src={getLogo(opt?.token)}
                                    width={30}
                                    className="coin_icon"
                                    alt=""
                                  />
                                  <span
                                    style={{
                                      marginLeft: "5px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {opt.token} | {network.title}
                                    <br></br>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        color: "rgb(136, 136, 136)",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {network.title}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
      <style jsx>{`
        .div {
          border-radius: 15px;
          background-color: #444;
          display: flex;
          max-width: 490px;
          flex-direction: column;
          align-items: center;
          padding: 21px 12px 25px;
        }
        .div-2 {
          display: flex;
          width: 100%;
          max-width: 427px;
          gap: 20px;
          font-size: 21px;
          color: #fff;
          font-weight: 600;
        }
        @media (max-width: 991px) {
          .div-2 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-3 {
          font-family: Montserrat, sans-serif;
          flex-grow: 1;
          flex-basis: auto;
        }
        .close_icon {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 20px;
          opacity: 0.75;
          cursor: pointer;
        }
        .close_icon:hover {
          opacity: 1;
          scale: 1.25;
        }
        .div-4 {
          font-family: Montserrat, sans-serif;
          border-radius: 9px;
          border: none;
          background-color: #333;
          display: flex;
          margin-top: 28px;
          width: 100%;
          max-width: 434px;
          gap: 20px;
          font-size: 14px;
          color: #fff;
          font-weight: 500;
          padding: 12px 14px;
        }
        @media (max-width: 991px) {
          .div-4 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .img-2 {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 17px;
        }
        .div-6 {
          border-radius: 3px;
          min-height: 200px;
          max-height: 200px;
          // background-color: #5b5959;
          display: flex;
          flex-wrap: wrap;
          margin-top: 15px;
          width: 100%;
          gap: 10px;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .div-6 {
            max-width: 100%;
            flex-wrap: wrap;
            // padding-right: 20px;
            white-space: initial;
          }
        }
        .div-7 {
          display: flex;
          align-items: start;
          gap: 10px;
        }
        @media (max-width: 991px) {
          .div-7 {
            white-space: initial;
          }
        }
        .img-3 {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 31px;
          align-self: stretch;
        }
        .div-8 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-8 {
            white-space: initial;
          }
        }
        .div-9 {
          color: #fff;
          font: 600 15px Inter, sans-serif;
        }
        .div-10 {
          color: #888;
          margin-top: 6px;
          font: 500 9px Inter, sans-serif;
        }
        .div-11 {
          color: #6c6c6c;
          font: 500 15px Inter, sans-serif;
        }
        .img-4 {
          aspect-ratio: 1.89;
          object-fit: auto;
          object-position: center;
          width: 15px;
          fill: #868585;
          margin: auto 0;
        }
        .div-12 {
          display: flex;
          margin-top: 16px;
          width: 100%;
          max-width: 430px;
          gap: 20px;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .div-12 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-13 {
          display: flex;
          gap: 14px;
        }
        .img-5 {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 31px;
        }
        .div-14 {
          align-self: start;
          display: flex;
          margin-top: 4px;
          flex-direction: column;
        }
        .div-15 {
          display: flex;
          gap: 9px;
          font-size: 15px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-15 {
            white-space: initial;
          }
        }
        .div-16 {
          color: #fff;
          font-family: Inter, sans-serif;
          font-weight: 600;
          flex-grow: 1;
        }
        .div-17 {
          color: #6c6c6c;
          font-family: Inter, sans-serif;
          font-weight: 500;
        }
        .div-18 {
          color: #888;
          margin-top: 4px;
          font: 500 9px Inter, sans-serif;
        }
        .img-6 {
          aspect-ratio: 1.89;
          object-fit: auto;
          object-position: center;
          width: 15px;
          fill: #868585;
          margin: auto 0;
        }
        .div-19 {
          display: flex;
          margin-top: 17px;
          width: 100%;
          max-width: 431px;
          gap: 20px;
          justify-content: space-between;
        }
        @media (max-width: 991px) {
          .div-19 {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }
        .div-20 {
          display: flex;
          gap: 14px;
        }
        .img-7 {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 31px;
        }
        .div-21 {
          align-self: start;
          display: flex;
          flex-direction: column;
        }
        .div-22 {
          display: flex;
          gap: 6px;
          font-size: 15px;
          white-space: nowrap;
        }
        @media (max-width: 991px) {
          .div-22 {
            white-space: initial;
          }
        }
        .div-23 {
          color: #fff;
          font-family: Inter, sans-serif;
          font-weight: 600;
        }
        .div-24 {
          color: #6c6c6c;
          font-family: Inter, sans-serif;
          font-weight: 500;
          flex-grow: 1;
          flex-basis: auto;
        }
        .div-25 {
          color: #888;
          margin-top: 4px;
          font: 500 9px Inter, sans-serif;
        }
        .img-8 {
          aspect-ratio: 1.89;
          object-fit: auto;
          object-position: center;
          width: 15px;
          fill: #868585;
          align-self: start;
          margin-top: 17px;
        }
      `}</style>
    </>
  );
};

export default SelectCust;
